const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/fostercaretech.com/default'
    basename: '',
    defaultPath: '',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 2,
    baseApiUrl: "https://ecap3-api-demo.fostercaretech.com",
    authZBaseApiUrl: "https://authz-demo.fostercaretech.com",
    appId: 'ECAP-E2-TFI',
    appName: "ECAP-E2-Demo-Demo",
    mapAPIKey: "AIzaSyBVMS87ShoBcETIhkPOPqEf3uC9lmSam9k",
    gaMeasurementId: "G-2WEKQQNEN6"
};

export default config;
