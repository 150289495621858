export default function themeEcap(theme, themeJson) {
    return {
        customInput: {
            '& input': {
                fontSize: 20
            },
            '& textarea': {
                fontSize: 20
            },
            '& .MuiSelect-select': {
                fontSize: 20
            },
            // margin: .5,
            fontSize: 20,
            root: {
                fontSize: 20,
                '& .MuiTypography-root': {
                    fontSize: 20
                },
                '& .MuiMenuItem-root': {
                    padding: 0,
                    margin: 0
                }
            }
        },
        customCard: {
            borderTop: '3px solid',
            borderTopColor: themeJson?.Secondary || theme.colors?.secondaryMain,
            bgcolor: 'background.paper',
            ':hover': {
                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
            },
        },
        primary: themeJson?.Primary || theme.colors?.primaryMain,
        secondary: themeJson?.Secondary || theme.colors?.secondaryMain,
    }
};