// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { useEffect, useState } from 'react';
import useECAPAxios from 'utils/ecapAxios';
import { IntakeFieldPosVals } from 'services/constants/attribute-constants';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
     paddingLeft: 16,
     paddingRight: 16,
    '& .MuiListItem-root': {
        padding: 0
    },
    border: '1px solid',
    margin: 2
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = (props) => {
    const theme = useTheme();

    const { notificationsDataLoading, notificationsFetchError, notificationsData } = props;
    
    return notificationsDataLoading ? <CircularProgress /> :  (
        
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                }
            }}
        >
                {(notificationsData && notificationsData.length) ? notificationsData.map(notDat => {
                    return (<ListItemWrapper sx={{
                        backgroundColor: (notDat.isRead ? 'white' : '#c8c8c878'),
                        color: 'black',
                        fontWeight: notDat.isRead ? 'inherit' : 'bold'
                    }}>
                        <ListItem alignItems="center">
                        <ListItemText sx={{display: 'flex', justifyContent: 'space-between'}} primary={notDat.title} secondary={notDat.datetime} secondaryTypographyProps={{color: 'black'}} style={{color: 'black'}} />
                        
                        </ListItem>
                        <Grid container direction="column" className="list-container">
                            <Grid item xs={12} sx={{ pb: 1 }}>
                                <Typography variant="subtitle2" sx={{color: 'black'}}>{notDat.message}</Typography>
                            </Grid>
                        </Grid>
                    </ListItemWrapper>)
                }): <ListItem>No notifications</ListItem>}
                
        </List>
    ) ;
};

export default NotificationList;
